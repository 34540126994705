import React, { useEffect, useState } from "react"
import '../lists.scss';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import Select from 'react-select';
import { dbURL, dbURLists, dbURListsRefs, customStyles2 } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import Highlighter from "react-highlight-words";
import {DebounceInput} from 'react-debounce-input';
import ReactPlayer from "react-player";
import { useParams, Link } from 'react-router-dom';
import SEO from '../../SEO';
import ReactMarkdown from 'react-markdown';
import { SearchLists, SelectProperty } from "../misc/utils";

const SongLists = (props) => {

    const [songs, setSongs] = useState([])
    const [list, setList] = useState([])
    const [references, setReferences] = useState([])
    const [loadingSongs, setLoadingSongs] = useState(true)
    const [loadingList, setLoadingList] = useState(true)
    const [errorSongs, setErrorSongs] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [errorReferences, setErrorReferences] = useState(null)
    const [optionArtist, setOptionArtist] = useState(null)
    const [optionYear, setOptionYear] = useState(null)
    const [optionAlbum, setOptionAlbum] = useState(null)
    const [optionSimpleAlbum, setOptionSimpleAlbum] = useState(null)
    const [optionLabel, setOptionLabel] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [searchStringLyrics, setSearchStringLyrics] = useState('')
    const [optionRange, setOptionRange] = useState('1-100')
    const [optionRange70s, setOptionRange70s] = useState('1970')
    const [AgGrid, setAgGrid] = useState(false)
    const [columnDefs] = useState([
        {headerName: "#", field: "rank", resizable: true, width: 60, cellStyle: { 'font-size': '14px' }}, 
        {headerName: "Song", field: "title", resizable: true, width: 230, cellStyle: { 'font-size': '14px' }},
        {headerName: "Artist", field: "artist", resizable: true, width: 230, cellStyle: { 'font-size': '14px' }},
        {headerName: "Album", field: "album", sortable: true, width: 280, cellStyle: { 'font-size': '14px' }},
        {headerName: "Year released", field: "year_released", sortable: true, width: 110, cellStyle: { 'font-size': '14px' }},
        {headerName: "Songwriter", field: "songwriter", filter: true, width: 300, cellStyle: { 'font-size': '14px' }}
      ])

    const params = useParams();

    useEffect(() => {
        const fetchList = () => {
            axios.get(dbURLists + params.slug)
            .then(response => {
                setLoadingList(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchSongs = () => {
            axios.get(dbURL + props.SongsAPI + params.slug)
            .then(response => {
                setLoadingSongs(false)
                setSongs(response.data)
            }).catch(errorSongs => {
                setErrorSongs(errorSongs);
            });
        }

        const fetchReferences = () => {
            axios.get(dbURListsRefs + params.slug)
            .then(response => {
                setReferences(response.data)
            }).catch(errorReferences => {
                setErrorReferences(errorReferences);
            });
        }

        fetchList()
        fetchSongs()
        fetchReferences()
      }, [params.slug, props.SongsAPI])

    if (errorList) return `Error List: ${errorList.message}`;
    if (errorSongs) return `Error Songs: ${errorSongs.message}`;
    if (errorReferences) return `Error References: ${errorReferences.message}`;

    if (loadingList || loadingSongs) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>404 - Not Found!</h1>
                                    <Link to="/lists">
                                        Go to current Lists
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    } 

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };


    const {sort_decending, ranked_list, hide_rank, comment3,
        simple_songs, show_other_versions, album_sort_recorded, origin_info, 
        name, description, picture_link, page, hide_grid_option, 
        songs_sort_released, songs_must_hear_year, new_songs_pending, 
        range_1970s, no_search_lyrics, markdown_personnel, single_artist, 
        label_filter, simple_album_select, no_album, honors_awards} = list[0];
    
    const {year_released} = songs[0];

    let songsInList, cameCloseSongs, rangeArray, ListPic, ListPic2, 
        sortedSongs, filterArray, searchWords, sortedReferences,
        ArtistOptions, YearOptions, AlbumOptions, songsWithLyrics, LabelOptions, 
        SimpleAlbumOptions


    ArtistOptions = SelectProperty(songs, 'artist')
    YearOptions = SelectProperty(songs, 'year_released')
    AlbumOptions = SelectProperty(songs, 'album')
    LabelOptions = SelectProperty(songs, 'label')
    SimpleAlbumOptions = SelectProperty(songs, 'album_new_select');
    

    const selectedOptionArtist = (selectedArtist) => {
        setOptionArtist(selectedArtist);
    }
    const selectedOptionYear = (selectedYear) => {
        setOptionYear(selectedYear);
    }
    const selectedOptionAlbum = (selectedAlbum) => {
        setOptionAlbum(selectedAlbum);
    }
    const selectedOptionSimpleAlbum = (selectedSimpleAlbum) => {
        setOptionSimpleAlbum(selectedSimpleAlbum);
    }
    const selectedOptionLabel = (selectedLabel) => {
        setOptionLabel(selectedLabel);
    }

    //AgGrid checkbox
    const checkHandler = () => {
        setAgGrid(!AgGrid)
      }
    
    //Filtrere came_close & lyrics
    songsInList = songs.filter(e => e.came_close == null);
    songsWithLyrics = songs.filter(e => e.lyrics !== null);
    cameCloseSongs = songs.filter(e => e.came_close !== null);

    //SORTERING
    if (sort_decending) {
        sortedSongs = songsInList.sort((a, b) => {
            return b.rank - a.rank;
        });
    }
    if (album_sort_recorded) {
        sortedSongs = songsInList.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.sort_recorded) - new Date(b.sort_recorded);
          });
        //console.log('HIT!!');
    }
    if (songs_sort_released) {
        sortedSongs = songsInList.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.sort_released) - new Date(b.sort_released);
          });
    }
    else {
        sortedSongs = songsInList;
    }

    sortedReferences = references.sort((a, b) => {
        return b.ref_number - a.ref_number;
    });

    

    //RangeSelection if large lists
    if (optionRange === '1-100') {
        rangeArray = sortedSongs.filter(e => e.rank < 101);  
        }
    if (optionRange === '101-200') {
        rangeArray = sortedSongs.filter(e => e.rank > 100 && e.rank < 201);  
        } 
    if (optionRange === '201-300') {
        rangeArray = sortedSongs.filter(e => e.rank > 200 && e.rank < 301);  
        } 
    if (optionRange === '301-400') {
        rangeArray = sortedSongs.filter(e => e.rank > 300 && e.rank < 401);  
        } 
    if (optionRange === '401-500') {
        rangeArray = sortedSongs.filter(e => e.rank > 400 && e.rank < 501);  
        } 
    if (optionRange === '501-600') {
        rangeArray = sortedSongs.filter(e => e.rank > 500 && e.rank < 601);  
        } 
    if (optionRange === '601-700') {
        rangeArray = sortedSongs.filter(e => e.rank > 600 && e.rank < 701);  
        } 
    if (optionRange === '701-800') {
        rangeArray = sortedSongs.filter(e => e.rank > 700 && e.rank < 801);  
        } 
    if (optionRange === '801-900') {
        rangeArray = sortedSongs.filter(e => e.rank > 800 && e.rank < 901);  
        } 
    if (optionRange === '901-1001') {
        rangeArray = sortedSongs.filter(e => e.rank > 900 && e.rank < 1002);  
        } 
    if (optionRange === 'All') {
        rangeArray = sortedSongs;  
        }

    //RangeSelection1970s decade
    if (range_1970s) {
        rangeArray = sortedSongs.filter(e => e.year_released === optionRange70s);  
        }


    //IF NOT Ranked List
    if (!ranked_list && !range_1970s) {
        rangeArray = sortedSongs; 
    }

    //SØK
    if (searchString) {
        filterArray = SearchLists(sortedSongs, 'searchtitle', searchString.toLowerCase());
        } 
    if (searchStringLyrics) {
        searchWords = searchStringLyrics.split(/\s/).filter(word => word)
        filterArray = SearchLists(songsWithLyrics, 'searchlyrics', searchStringLyrics.toLowerCase());
    }

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }
    
    const handleSearchChangeLyrics = (e) => {
        setSearchStringLyrics(e.target.value);
        };

    const clearSearchFieldLyrics = (e) => {
        setSearchStringLyrics('')
        }

    //FILTERS
    if (optionAlbum) {
        filterArray = sortedSongs.filter(e => e.album === optionAlbum.value);
        } 

    if (optionSimpleAlbum) {
        filterArray = sortedSongs.filter(e => e.album_new_select === optionSimpleAlbum.value);
        }

    if (optionYear) {
        filterArray = sortedSongs.filter(e => e.year_released === optionYear.value);
        }  
    if (optionArtist) {
        filterArray = sortedSongs.filter(e => e.artist === optionArtist.value);
        } 

    if (optionLabel) {
        filterArray = sortedSongs.filter(e => e.label === optionLabel.value);
        }

    if (!rangeArray && !searchString && !searchStringLyrics && !optionArtist && !optionYear && !optionAlbum && !optionLabel && !optionSimpleAlbum) {
        filterArray = sortedSongs;
    }
    if (rangeArray && !searchString && !searchStringLyrics && !optionArtist && !optionYear && !optionAlbum && !optionLabel && !optionSimpleAlbum) {
        filterArray = rangeArray;
    }

    //HEADING
    const listHeading = list.map(value => {

        ListPic = value.picture_link
        ListPic2 = value.picture2

        return (
            <React.Fragment key={value.id}>
                <div>
                    <h1>{value.name}</h1>

                    <div className="row">

                        {/* kolonne 1 */}
                        <div className="col-sm-3 mb-3">
                                
                                <img src={ListPic}
                                alt="alt" className="img-fluid" /> 

                                {
                                    (() => {
                                    if (ListPic2) {
                                    return  <div className='mt-2'><img src={ListPic2} alt="alt" className="img-fluid" /> </div>
                                    }
                                    return 
                                    })()
                                }

                                {value.facebook_left &&
                                    <div className="mt-3">
                                        Please check out our <b>Facebook page</b> for daily updates:
                                        -> <b><a href='https://www.facebook.com/JohannasVisions'>Facebook</a></b>
                                    </div>
                                }

                                
                                {((window.location.hostname === "localhost") || (value.in_progress)) &&
                                <div>
                                    <br />
                                    <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                    
                                    <br />
                                     <div className="text-center">
                                        <div className="btn btn-info btn-xs">total count: {songs.length}</div>
                                    </div>  
                                </div>
                                }
                                
                            </div>  

                        {/* kolonne 2 */}
                        <div className="col-sm-5">
                            
                            {
                                (() => {
                                    if (value.sub_title) {
                                    return  <div className="SubTitle px-3 py-1 mb-2">{value.sub_title}</div>
                                    }
                                    return 
                                })()
                            }
                            {
                                (() => {
                                    if (value.description) {
                                    return  <div className="factTextNoWrap rounded px-2 pt-2 pb-1 mb-2">
                                                <ReactMarkdown>
                                                    {value.description}                     
                                                </ReactMarkdown>
                                            </div>
                                    }
                                    return 
                                })()
                            }
                            {
                                (() => {
                                    if (value.comment1) {
                                    return  <div className="card bg-light px-2 pt-2 mb-3">
                                                <div className="quote2 mb-3">{value.comment1}</div> 
                                                <div className="blockquote-footer"><i>{value.source1}</i></div>
                                            </div>
                                    }
                                    return 
                                })()
                            }
                            
                            {value.url &&
                                <div>
                                    <b>List source</b>
                                    <div className="Description">
                                        <ul>
                                            <li>
                                                <b><a href={value.url}>
                                                    {value.name} ({value.source})
                                                </a></b>
                                            </li>  
                                        </ul>
                                    </div>
                                </div>
                            }
                            {!value.links_right &&
                            <div>
                                <div className="mt-3">
                                    <b>Also check out:</b>
                                    </div>
                                    <div className="Description">
                                    <ul>
                                        {value.link1 &&
                                        <li><b><a href={value.url1}>{value.link1}</a></b></li>
                                        }
                                        {value.link2 &&
                                        <li><b><a href={value.url2}>{value.link2}</a></b></li>
                                        }
                                        {value.link3 &&
                                        <li><b><a href={value.url3}>{value.link3}</a></b></li>
                                        }
                                        {value.link4 &&
                                        <li><b><a href={value.url4}>{value.link4}</a></b></li>
                                        }
                                        {value.link5 &&
                                        <li><b><a href={value.url5}>{value.link5}</a></b></li>
                                        }
                                        
                                        <li><b><a href='/lists'>More Lists @ MusicThisDay.com</a></b></li>
                                    </ul>   
                                </div>
                            </div>
                            }
  
                            <p className="Description"><i>All lyrics are the property and copyright of 
                                their owners, provided for educational purposes only.</i></p>

                            {!value.facebook_left &&
                            <div>
                                Please check out our <b>Facebook page</b> for daily updates:
                                -> <b><a href='https://www.facebook.com/JohannasVisions'>Facebook</a></b>
                            </div>
                            }
                        
                        </div>  
                        
                        {/* kolonne 3 */}
                        <div className="col-sm-4 mb-1"> 
                            {(!searchString && !searchStringLyrics && !value.no_filters)  &&
                                <div className="row">   
                                    <div className="col">
                                        <h4>Filters</h4>
                                    </div>
                                </div>
                            }
                            {(!searchString && !searchStringLyrics && !value.single_artist && !optionAlbum && !optionYear)  &&
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={optionArtist}
                                            onChange={selectedOptionArtist}
                                            options={ArtistOptions}
                                            placeholder='Select artist..'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => setOptionArtist(null)} >Reset</button>
                                    </div>
                                </div>
                            }
                            {(!searchString && !searchStringLyrics && !simple_album_select && !no_album 
                            && !optionArtist && !optionYear && !optionLabel)  &&
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={optionAlbum}
                                            onChange={selectedOptionAlbum}
                                            options={AlbumOptions}
                                            placeholder='Select album..'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => setOptionAlbum(null)} >Reset</button>
                                    </div>
                                </div>
                            }
                            {(!searchString && !searchStringLyrics && simple_album_select && !no_album 
                            && !optionArtist && !optionYear && !optionLabel)  &&
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={optionSimpleAlbum}
                                            onChange={selectedOptionSimpleAlbum}
                                            options={SimpleAlbumOptions}
                                            placeholder='Select album..'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => setOptionSimpleAlbum(null)} >Reset</button>
                                    </div>
                                </div>
                            }
                            {(!searchString && !searchStringLyrics && !value.no_year_released && !optionAlbum && !optionArtist && !optionLabel)  &&
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={optionYear}
                                            onChange={selectedOptionYear}
                                            options={YearOptions}
                                            placeholder='Select year..'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => setOptionYear(null)} >Reset</button>
                                    </div>
                                </div>
                            }
                            {(!searchString && !searchStringLyrics && !value.no_year_released && !optionAlbum && !optionArtist 
                            && !optionYear && label_filter)  &&
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={optionLabel}
                                            onChange={selectedOptionLabel}
                                            options={LabelOptions}
                                            placeholder='Select label..'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => setOptionLabel(null)} >Reset</button>
                                    </div>
                                </div>
                            }
                            {(!optionAlbum && !optionArtist && !optionYear && !optionLabel) && 
                            <div>
                                <div className="row">   
                                        <div className="col">
                                        <h4>Search</h4>
                                    </div>
                                </div>

                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <div className="search">    
                                                <DebounceInput
                                                    minLength={3}
                                                    debounceTimeout={1000}
                                                    value={searchString}
                                                    onChange={handleSearchChange}
                                                    placeholder="Search all song titles" 
                                                />
                                            </div>
                                        </div>
                                    <div className="col-3 pl-0">
                                        <button type="button" className="btn btn-primary"  
                                        onClick={() => clearSearchField()} >Reset</button>
                                    </div>
                                </div>

                                {!no_search_lyrics &&
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <div className="search">    
                                            <DebounceInput
                                                minLength={3}
                                                debounceTimeout={1000}
                                                value={searchStringLyrics}
                                                onChange={handleSearchChangeLyrics}
                                                placeholder="Search all lyrics" 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3 pl-0">
                                        <button type="button" className="btn btn-primary"  
                                        onClick={() => clearSearchFieldLyrics()} >Reset</button>
                                    </div>
                                </div>
                                }
                            </div>
                            }

                            {!hide_grid_option &&
                                <div className="row align-items-start no-gutters">     
                                    <div className="col pt-2">
                                        <div className="form-check mb-3">
                                            <label className="form-check-label">
                                            <input type="checkbox"
                                                checked={AgGrid}
                                                onChange={checkHandler}
                                                className="form-check-input"
                                            />
                                            Change to Grid
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }

                            {(searchString||searchStringLyrics) &&
                                <div>
                                    <div className='mt-3'>
                                        <div className="btn btn-info btn-xs">total hits: {filterArray.length}</div>
                                    </div> 
                                </div>
                            }
                            {!optionArtist && !searchString && !searchStringLyrics && value.range_1970s &&
                                <div className="m-3 p-2 border rounded DateInline">Viewing songs from <b>{optionRange70s}</b></div>
                            }
                            {optionArtist && !searchString && !searchStringLyrics &&
                                <div className="mt-3 p-2 border rounded DateInline">Selected songs by <b>{optionArtist.value}</b></div>
                            }

                            
                            {(value.spotify &&  value.spotify_title) &&
                            <div>
                                <div className="row mt-2">   
                                            <div className="col">
                                            <h4>Spotify</h4>
                                        </div>
                                </div> 
                                <div className='mb-2 ml-4'><b>--> <a href={ value.spotify }>{ value.spotify_title }</a></b></div>
                            </div>
                            }
                            {(value.spotify &&  !value.spotify_title) &&
                            <div>
                                
                                <div className='mb-2 mt-4 ms-4'><b>Spotify --> <a href={ value.spotify }>Spotify playlist</a></b></div>
                            </div>
                            } 

                            {value.links_right &&
                            <div>
                                <div className="mt-3">
                                    <b>Also check out:</b>
                                    </div>
                                    <div className="Description">
                                    <ul>
                                        {value.link1 &&
                                        <li><b><a href={value.url1}>{value.link1}</a></b></li>
                                        }
                                        {value.link2 &&
                                        <li><b><a href={value.url2}>{value.link2}</a></b></li>
                                        }
                                        {value.link3 &&
                                        <li><b><a href={value.url3}>{value.link3}</a></b></li>
                                        }
                                        {value.link4 &&
                                        <li><b><a href={value.url4}>{value.link4}</a></b></li>
                                        }
                                        {value.link5 &&
                                        <li><b><a href={value.url5}>{value.link5}</a></b></li>
                                        }
                                        
                                        <li><b><a href='/lists'>More Lists @ MusicThisDay.com</a></b></li>
                                    </ul>   
                                </div>
                            </div>
                            }
                        </div>
                    </div>                    
                </div>

                {(value.range_500 || value.range_200 || value.range_1001) && !optionArtist &&  !optionYear && !searchString && !searchStringLyrics &&
                    <div className="row">
                    
                        <div className='col-sm-10 mb-2' align="Left">
                            <div className='mb-2'>Select range to filter:</div>
                            {optionRange === '1-100' && 
                            <button className="btn btn-info btn-sm m-1" onClick={() => setOptionRange('1-100')}>1-100</button>
                            }
                            {optionRange !== '1-100' && 
                            <button type="button" className="btn btn-info btn-xs m-1" onClick={() => setOptionRange('1-100')}>1-100</button>
                            }
                            {/* <button type="button" className="btn btn-info btn-sm mr-1" onClick={() => setOptionRange('101-200')}>101-200</button> */}

                            {optionRange === '101-200' && 
                            <button type="button" className="btn btn-info btn-sm m-1" onClick={() => setOptionRange('101-200')}>101-200</button>
                            }
                            {optionRange !== '101-200' && 
                            <button type="button" className="btn btn-info btn-xs m-1" onClick={() => setOptionRange('101-200')}>101-200</button>
                            }


                            {(value.range_500 || value.range_1001) &&
                            <>
                            <button type="button" className="btn btn-info btn-sm m-1" onClick={() => setOptionRange('201-300')}>201-300</button>
                            <button type="button" className="btn btn-info btn-sm m-1" onClick={() => setOptionRange('301-400')}>301-400</button>
                            <button type="button" className="btn btn-info btn-sm m-1" onClick={() => setOptionRange('401-500')}>401-500</button>
                            </>
                            }
                            {(value.range_1001) &&
                            <>
                            <button type="button" className="btn btn-sm btn-info mr-2 mt-lg-0 m-1" onClick={() => setOptionRange('501-600')}>501-600</button>
                            <button type="button" className="btn btn-info btn-sm mr-2 mt-lg-0 m-1" onClick={() => setOptionRange('601-700')}>601-700</button>
                            <button type="button" className="btn btn-info btn-sm mr-2 mt-lg-0 m-1" onClick={() => setOptionRange('701-800')}>701-800</button>
                            <button type="button" className="btn btn-info btn-sm mr-2 mt-lg-0 m-1" onClick={() => setOptionRange('801-900')}>801-900</button>
                            <button type="button" className="btn btn-info btn-sm mt-lg-0 m-1" onClick={() => setOptionRange('901-1001')}>901-1001</button>
                            </>
                            }
                        </div>

                    

                        <div className="col-sm-2 pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                }

                {value.range_1970s && !optionArtist && !searchString && !searchStringLyrics &&
                    <div className="row">
                        <div className='col-sm-10 mb-2' align="Left">
                            <div className='mb-2'>Select year to filter:</div>
                            {optionRange70s === '1970' && 
                                <button type="button" className="btn btn-success btn-sm m-1"  onClick={() => setOptionRange70s('1970')}>1970</button>
                            }
                            {optionRange70s !== '1970' && 
                            <button type="button" className="btn btn-info btn-xs m-1" onClick={() => setOptionRange70s('1970')}>1970</button>
                            }
                            {optionRange70s === '1971' && 
                            <button type="button" className="btn btn-success btn-sm m-1" onClick={() => setOptionRange70s('1971')}>1971</button>
                            }
                            {optionRange70s !== '1971' &&
                            <button type="button" className="btn btn-info btn-xs m-1" onClick={() => setOptionRange70s('1971')}>1971</button>
                            }
                            {optionRange70s === '1972' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setOptionRange70s('1972')}>1972</button>
                            }
                            {optionRange70s !== '1972' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setOptionRange70s('1972')}>1972</button>
                            }
                            {optionRange70s === '1973' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setOptionRange70s('1973')}>1973</button>
                            }
                            {optionRange70s !== '1973' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setOptionRange70s('1973')}>1973</button>
                            }
                            {optionRange70s === '1974' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setOptionRange70s('1974')}>1974</button>
                            }
                            {optionRange70s !== '1974' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setOptionRange70s('1974')}>1974</button>
                            }
                            {optionRange70s === '1975' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setOptionRange70s('1975')}>1975</button>
                            }
                            {optionRange70s !== '1975' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setOptionRange70s('1975')}>1975</button>
                            }
                            {optionRange70s === '1976' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setOptionRange70s('1976')}>1976</button>
                            }
                            {optionRange70s !== '1976' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setOptionRange70s('1976')}>1976</button>
                            }
                            {optionRange70s === '1977' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setOptionRange70s('1977')}>1977</button>
                            }
                            {optionRange70s !== '1977' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setOptionRange70s('1977')}>1977</button>
                            }
                            {optionRange70s === '1978' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setOptionRange70s('1978')}>1978</button>
                            }
                            {optionRange70s !== '1978' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setOptionRange70s('1978')}>1978</button>
                            }
                            {optionRange70s === '1979' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setOptionRange70s('1979')}>1979</button>
                            }
                            {optionRange70s !== '1979' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setOptionRange70s('1979')}>1979</button>
                            }
                        </div>
                    </div>
                }

                {(!value.range_500 && !value.range_200 && !value.range_1001) && 
                    <div className="row">
                        <div className="col pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
     }
    )

    //SONGS
    const songItems = filterArray.map(value => {

        let LyricsCharLimit, DescCharLimit, LineUpCharLimit, row_group_start_class, 
        album_row_class

        //LYRICS
        if (value.charlimitlyrics) {
            LyricsCharLimit  = value.charlimitlyrics;
        }
        if (!value.charlimitlyrics && value.lyrics_selected_songs) {
            LyricsCharLimit = value.lyrics_selected_songs;
        }

        if (!value.charlimitlyrics && !value.lyrics_selected_songs && value.song_slim && !value.description) {
            LyricsCharLimit = 350
        }
        if (!value.charlimitlyrics && !value.lyrics_selected_songs && value.song_slim && value.description) {
            LyricsCharLimit = 200
        }
        if (!value.charlimitlyrics && !value.lyrics_selected_songs && !value.song_slim && value.description && value.comment) {
            LyricsCharLimit = 500
        }

        if (!value.charlimitlyrics && !value.lyrics_selected_songs && !value.song_slim && value.description && !value.comment) {
            LyricsCharLimit = 300
        }
        if (!value.charlimitlyrics && !value.lyrics_selected_songs && !value.song_slim && !value.description) {
            LyricsCharLimit = 500
        }

        //DESCRIPTION
        if (value.desc_selected_songs) {
            DescCharLimit = value.desc_selected_songs;
        }
        if (value.song_slim && !value.desc_selected_songs) {
            DescCharLimit = 300
        }
        if (!value.song_slim && !value.desc_selected_songs) {
            DescCharLimit = 500
        }

        //LINE-UP
        if (value.charlimitline_up) {
            LineUpCharLimit = value.charlimitline_up;
        }
        if (!value.charlimitline_up && value.line_up_songs) {
            LineUpCharLimit = value.line_up_songs;
        }
        if (!value.charlimitline_up && !value.line_up_songs) {
            LineUpCharLimit = 300
        }

        row_group_start_class = 'row sortTitle p-2 border-start border-end border-top border-4';

        if (value.group_name && value.group_start && !optionArtist) {
            album_row_class = 'row p-3 border-start border-end border-top border-4';
        }
        if (value.group_name && value.group_start && optionArtist) {
            album_row_class = 'row mb-4 p-3 border border-4';
        }

        if (value.group_name && !value.group_end && !value.group_start && !optionArtist) {
            album_row_class = 'row AlbumSuperslim p-2 border-start border-end border-bottom border-4';
        }
        if (value.group_name && !value.group_end && !value.group_start && optionArtist) {
            album_row_class = 'row AlbumSuperslim mb-4 p-2 border-start border-end border-bottom border-4';
        }

        if (value.group_name && value.group_end) {
            album_row_class = 'row AlbumSuperslim mb-5 p-2 border-start border-end border-bottom border-4';
        }
        if (!value.group_name){
            album_row_class = 'row p-3 mb-4 border rounded border-2';
        }


        return (
           
            <React.Fragment key={value.key_id}>

                {value.group_name && value.group_start &&
                    <div className={row_group_start_class}>
                        <div className='col'>
                                {value.group_name}
                        </div>
                    </div>
                }

                {value.group_name && optionArtist && !value.group_start &&
                    <div className={row_group_start_class}>
                        <div className='col'>
                                {value.group_name}
                        </div>
                    </div>
                }

                {value.group_name && searchString && !value.group_start &&
                    <div className={row_group_start_class}>
                        <div className='col'>
                                {value.group_name}
                        </div>
                    </div>
                }

                    {
                        (() => {
                        if (value.song_super_slim && !value.group_name) {
                            return <div>
                                <div className="row AlbumSuperslim mb-4 p-2 border border-4">
                                    <div className='col'>
                                        {(value.pic_link || value.picture) &&
                                        <div className="float-md-end ms-md-2">
                                            <img border="0" alt="album" src={value.pic_link||value.picture} width="75" height="75" />
                                        </div>  
                                        }
                                        {value.rank &&
                                            <div className="bestSongsTitle"><b>#{value.rank} - {value.title}</b></div>
                                        }
                                        {!value.rank &&
                                            <div className="bestSongsTitle"><b>{value.title}</b></div>
                                        }
                                        {value.artist &&
                                            <div>{value.artist} ({value.sr_text||value.year_released})</div> 
                                        }
                                    </div>
                                </div>
                            </div>
                        } if (value.group_name && honors_awards && !value.group_start) {
                            return <div>
                                    {/* KUN en enkel rad i Group*/}
                                    <div className={album_row_class}>
                                        <div className='col'>
                                            {value.picture &&
                                            <div className="float-md-end ms-md-2">
                                                <img border="0" alt="album" src={value.picture} width="75" height="75" />
                                            </div>  
                                            }
                                             {value.pic_link &&
                                            <div className="float-md-end ms-md-2">
                                                <img border="0" alt="album" src={value.pic_link} width="75" height="75" />
                                            </div>  
                                            }
                                             {value.album_picture &&
                                            <div className="float-md-end ms-md-2">
                                                <img border="0" alt="album" src={value.album_picture} width="75" height="75" />
                                            </div>  
                                            }
                                            <div className="bestSongsTitle"><b>{value.title}</b></div>
                                            {value.artist &&
                                                <div>{value.artist}; Written by: {value.songwriter}</div> 
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                        if ((value.song_slim || simple_songs) && !(value.group_name && honors_awards && !value.group_start)) {
                            return <div className="row py-3 mb-4 border rounded border-2">
                                {/* 1 kolonne */}
                                <div className="col-sm-3">
                                    {value.pic_link &&
                                        <img src={value.pic_link} alt=""
                                        className="img-fluid"></img>
                                    }
                                    {!value.pic_link && value.picture &&
                                        <img src={value.picture} alt=""
                                        className="img-fluid"></img>
                                    }
                                    {!value.pic_link && !value.picture && value.album_picture &&
                                        <img src={value.album_picture} 
                                        className="img-fluid" alt=""></img>
                                    }

                                    {value.artist_text &&
                                        <div align="center">
                                            <div className="BestSong my-2 px-1 rounded">by <b>{value.artist_text}</b></div>
                                        </div> 
                                    }       
                                    {!single_artist && value.artist && !value.artist_text &&
                                        <div align="center">
                                            <div className="BestSong my-2 px-1 rounded">by <b>{value.artist}</b></div>
                                        </div> 
                                    }
                                    

                                </div>

                                {/* 2 kolonne */}
                                <div className="col-sm-3">
                                    {value.title && !value.rank &&
                                        <div>
                                            <div className="videoTitle py-1 ps-3 mb-2 rounded"><b>{value.title}</b></div>
                                        </div>
                                    }
                                    {value.title && value.rank &&
                                        <div>
                                            <div className="videoTitle py-1 ps-3 mb-2 rounded"><b>#{value.rank} - {value.title}</b></div>
                                        </div>
                                    }
                                    
                                    {/* spesielle lyrics baserte lister */}
                                    {(value.selected_lyrics || value.list_specific_text) &&
                                    <div className="mb-3 rounded">
                                        <div className="Date15Wrap px-3 py-1 rounded">
                                            <ReactMarkdown>
                                                {value.selected_lyrics||value.list_specific_text}                    
                                            </ReactMarkdown> 
                                        </div>
                                    </div>
                                    }

                                    {value.album &&
                                        <div><b>Album</b> <br />
                                            <div className="factText15 p-1 rounded">{value.album}</div>
                                            <div className="Date15 px-1 rounded"><i>{value.album_rd||value.ar_text}</i></div>
                                        </div>
                                    }
                                    {value.album_txt && !value.album &&
                                        <div><b>Album</b> <br />
                                            <div className="factText15 p-1 rounded">{value.album_txt}</div>
                                            <div className="Date15 px-1 rounded"><i>{value.album_released}</i></div>
                                        </div>
                                    }

                                   {/*  {value.year_released && !value.album_released &&
                                        <div className="mt-1"><b>Year</b> <br />
                                            <div className="factText15 p-1 rounded">{value.year_released}</div>
                                        </div>
                                    } */}

                                    {value.recorded && value.studio_txt &&
                                        <div className="mt-1"><b>Recorded</b> <br />
                                            <div className="Date15 p-1 rounded">{value.recorded} @ {value.studio_txt}</div>
                                        </div>
                                    }
                                    {value.recorded && !value.studio_txt &&
                                        <div className="mt-1"><b>Recorded</b> <br />
                                            <div className="Date15 p-1 rounded">{value.recorded}</div>
                                        </div>
                                    }
                                    {value.rd_text && value.studio_txt &&
                                        <div className="mt-1"><b>Recorded</b> <br />
                                            <div className="Date15 p-1 rounded">{value.rd_text} @ {value.studio_txt}</div>
                                        </div>
                                    }
                                    {value.rd_text && !value.studio_txt &&
                                        <div className="mt-1"><b>Recorded</b> <br />
                                            <div className="Date15 p-1 rounded">{value.rd_text}</div>
                                        </div>
                                    }

                                    {value.genre &&
                                        <div className="mt-1"><b>Genre</b> <br />
                                            <div className="Date15 p-1 rounded">{value.genre}</div>
                                        </div>
                                    }

                                    {value.single_released &&
                                        <div className="mt-1"><b>Single released</b> <br />
                                            <div className="Date15 p-1 rounded">{value.single_released}</div>
                                        </div>
                                    }
                                    {value.sr_text &&
                                        <div className="mt-1"><b>Single released</b> <br />
                                            <div className="factText15 p-1 rounded">{value.sr_text}</div>
                                        </div>
                                    }

                                    {value.length && !value.list_specific_text &&
                                        <div className="mt-1"><b>Length</b> <br />
                                            <div className="factText15 p-1 rounded">{value.length}</div>
                                        </div>
                                    }

                                    {value.label && !value.list_specific_text &&
                                        <div className="mt-1"><b>Label</b> <br />
                                            <div className="factText15 p-1 rounded">{value.label}</div>
                                        </div>
                                    }

                                    {value.songwriter && !value.list_specific_text &&
                                        <div className="mt-1 mb-2"><b>Songwriter</b> <br />
                                            <div className="factText15 p-1 rounded">{value.songwriter}</div>
                                        </div>
                                    }
                                    

                                    {(value.blues_hall_of_fame || value.grammy_hall_of_fame || value.library_of_congress) &&
                                        <div className="p-3 mt-3 border rounded bg-success text-white Description">
                                            {value.blues_hall_of_fame &&
                                            <div className="mt-1 ms-3"><b>Blues Hall of Fame: </b> 
                                            {value.blues_hall_of_fame}
                                            </div>
                                            }
                                            {value.grammy_hall_of_fame &&
                                                <div className="mt-1 ms-3"><b>Grammy Hall of Fame: </b> 
                                                {value.grammy_hall_of_fame}
                                                </div>
                                            }
                                            {value.library_of_congress &&
                                                <div className="mt-1 ms-3"><b>National Recording Registry: </b> 
                                                {value.library_of_congress}
                                                </div>
                                            }
                                        </div>
                                    }

                                    {value.wiki &&
                                        <div className="Description mt-2"> 
                                        <em> -> <a href={value.wiki}>more info..</a></em>
                                       </div>
                                    }
                                    
                                </div>

                                {/* 3 kolonne */}
                                <div className="col-sm-3">
                                
                                    
                                    {value.description && !value.markdown_description &&
                                        <div className="Description">
                                            <ReadMoreAndLess
                                                    charLimit={DescCharLimit}
                                                    readMoreText={" Read more ▼"}
                                                    readLessText={" Read less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.description}
                                            </ReadMoreAndLess>
                                        </div>
                                    }

                                    {value.description && value.markdown_description &&
                                        <div>
                                            <div className="factTextMarkdown p-1 rounded">
                                                <ReactMarkdown>
                                                    {value.description}                    
                                                </ReactMarkdown> 
                                            </div>
                                        </div>
                                    }  

                                    {value.main_comment && !value.list_specific_text &&
                                        <div className="card bg-light py-2 px-2">
                                            <div className="quote3 mb-3">{value.main_comment}</div>   
                                            <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                                        </div>
                                    } 

                                    {!searchStringLyrics && value.lyrics &&
                                        <div className="bg-light mx-2 mt-2">
                                            <div className="p-2 Description">
                                                <ReadMoreAndLess
                                                        charLimit={LyricsCharLimit}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                    >
                                                    {value.lyrics}
                                                </ReadMoreAndLess>
                                        
                                            </div>
                                        </div>
                                    }
                                    
                                    {searchStringLyrics &&
                                        <div className="bg-light mx-2 mt-2">
                                            <div className="p-2 Description">
                                
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={searchWords}
                                                        autoEscape={true}
                                                        textToHighlight={value.lyrics}
                                                    />
                                            </div>
                                        </div>
                                    }

                                </div>

                                {/* 4 kolonne */}
                                <div className="col-sm-3">
                                    
                                    {value.youtube &&
                                        <div>
                                            <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                            <div className="border d-flex justify-content-center flex-nowrap">  
                                                 <ReactPlayer
                                                    url={ value.youtube }
                                                    controls
                                                    light='true'
                                                    volume={0.9}
                                                    width="380px"
                                                    height="300px"
                                                    /> 
                                            </div>

                                        </div>
                                    }

                                </div>
                            </div>
                        }
                        return <div className={album_row_class}>
                                    
                                    {/* 1 kolonne */}
                                    <div className="col-sm-2 text-center">
                                        {
                                            (() => {
                                            if (value.list_id === 128) {
                                                return <div className="Song2 mb-2">
                                                    <b>{value.rank} - {value.list_choice_comment}</b><br />
                                                    <b>{value.title}</b>
                                                </div>
                                                }
                                            if (value.list_id === 192) {
                                                return  <div className='Song2'><h5>#{value.times_live}</h5><b>{value.rank} - {value.title}</b></div>     
                                            }
                                            if (value.rank && !hide_rank) {
                                                return  <div className='Song px-1 rounded'><b>#{value.rank} <br /> {value.title}</b></div>  
                                            }
                                                return <div className='Song px-1 rounded'><b>{value.title}</b></div> 
                                            })()
                                        }

                                        <div align="center">
                                            {
                                                (() => {
                                                if (value.picture && !value.pic_link) {
                                                return  <div className='mt-2'><img src={value.picture} alt=""
                                                className="img-fluid"></img></div>
                                                }
                                                if (!value.picture && value.pic_link) {
                                                    return  <div className='mt-2'><img src={value.pic_link} alt=""
                                                    className="img-fluid"></img></div>
                                                    }
                                                if (value.picture && value.pic_link) {
                                                    return  <div className='mt-2'><img src={value.pic_link} alt=""
                                                    className="img-fluid"></img></div>
                                                    }
                                                return <div className='mt-2'><img src={value.album_picture} className="img-fluid" alt=""></img></div>
                                                })()
                                            }
                                            {!single_artist &&
                                            <div align="center">
                                                <div className="Song2 my-2 px-1 rounded">by <b>{value.artist}</b></div>
                                            </div> 
                                            }
                                            {
                                                (() => {
                                                if (origin_info && value.origin_place) {
                                                    return <div className='mb-2'>
                                                            <b>Band origin</b> <br />
                                                            <div className="factText">{value.origin_place}</div>
                                                        </div>
                                                }
                                                if (origin_info && value.born_place) {
                                                    return <div className='mb-2'>
                                                            <b>Born</b> <br />
                                                            <div className="factText">{value.born_place}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }

                                            <div className="mt-1"><b>Songwriter</b></div>
                                            <div className="factText px-1 mb-1">{value.songwriter}</div>
                                            {
                                                (() => {
                                                if (value.lead_vocal) {
                                                    return <div className='mb-2'>
                                                            <b>Lead vocals</b> <br />
                                                            <div className="factText">{value.lead_vocal}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.youtube) {
                                                    return <div>
                                                            <div className='mt-3' align='center'><b>Click to play</b></div>
                                                            <div className="mt-1 border">
                                                                <ReactPlayer
                                                                    url={ value.youtube }
                                                                            light='true'
                                                                            //light
                                                                            controls
                                                                            width="280"
                                                                            height="200"
                                                                />
                                                            </div>
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.cover_version) {
                                                    return <div><button type="button" className="btn btn-info btn-xs mt-2">Cover version</button>
                                                    <br /><br />
                                                    </div>
                                                }
                                                return 
                                                })()
                                            }
                                                            
                                            {
                                                (() => {
                                                if (value.main_composer) {
                                                    return <div>
                                                            <b>Main writer</b> <br />
                                                            <div className="factText">{value.main_composer}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }

                                            {
                                                (() => {
                                                if (value.original_performer) {
                                                    return <div>
                                                            <b>Original performer</b> <br />
                                                            <div className="factText">{value.original_performer}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }

                                            {
                                                (() => {
                                                if (value.youtube_2) {
                                                    return <div className="mt-3 border">
                                                        <div>
                                                            <ReactPlayer
                                                                    url={ value.youtube_2 }
                                                                            light='true'
                                                                            //light
                                                                            controls
                                                                            width="250"
                                                                            height="250"
                                                                />
                                                        </div>
                                                        <div className='factText2 mt-4'>
                                                            {value.live_version_comment}
                                                        </div>
                                                </div>
                                                }
                                                return 
                                                })()
                                            }
                                            
                                        </div>

                                    </div>
                                

                                    {/* 2 kolonne */}
                                    <div className="col-sm-5 border-right Description">

                                        <div className="row">
                                            <div className="col py-2 border-right Description">

                                                {
                                                (() => {
                                                if (value.description) {
                                                return  <div>
                                                            <ReadMoreAndLess
                                                                    charLimit={DescCharLimit}
                                                                    readMoreText={" Read more ▼"}
                                                                    readLessText={" Read less ▲"}
                                                                    readMoreClassName="read-more-less--more"
                                                                    readLessClassName="read-more-less--less"
                                                                >
                                                                {value.description}
                                                            </ReadMoreAndLess>
                                                            <div className="Description"> 
                                                                <br /> <em> -> <a href={value.wiki}>more info..</a></em>
                                                                </div>
                                                    </div>
                                                }
                                                if (!value.description && value.comment) {
                                                    return <div className="card bg-light py-2 px-2">
                                                                <div className="quote3 mb-3">{value.comment}</div>
                                                                    {
                                                                        (() => {
                                                                                if (value.date_txt) {
                                                                                    return <div className="blockquote-footer"><i>{value.people_txt} ({value.date_txt})</i></div>
                                                                                }
                                                                                return <div className="blockquote-footer"><i>{value.people_txt}</i></div>

                                                                        })()

                                                                    }
                                                        </div>
                                                        
                                                    }
                                                    return 
                                                    })()
                                                }
                                                {value.main_comment && value.group_name &&
                                                    <div>
                                                        <div className="card bg-light py-2 px-2">
                                                                <div className="quote3 mb-3">{value.main_comment}</div>
                                                                <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>

                                        <div className="row pt-1">
                                            
                                            {
                                                (() => {
                                                    if (value.lyrics && !searchStringLyrics) {
                                                        return  <div className="bg-light ml-2 mr-2 rounded">
                                                                    <div className="p-2">
                                                                        <ReadMoreAndLess
                                                                                charLimit={LyricsCharLimit}
                                                                                readMoreText={" Read more ▼"}
                                                                                readLessText={" Read less ▲"}
                                                                                readMoreClassName="read-more-less--more"
                                                                                readLessClassName="read-more-less--less"
                                                                            >
                                                                            {value.lyrics}
                                                                        </ReadMoreAndLess>
                                                                
                                                                    </div>
                                                                </div>
                                                        }
                                                    if (value.lyrics && searchStringLyrics) {
                                                    return  <div className="bg-light ml-2 mr-2">
                                                                <div className="py-2 px-2">
                                                    
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={searchWords}
                                                                            autoEscape={true}
                                                                            textToHighlight={value.lyrics}
                                                                        />
                                                                </div>
                                                            </div>

                                                    }
                                                    return <p>No lyrics..yet.</p>
                                                    }
                                                )()
                                            }
                                                                            
                                                                            
                                        </div>   

                                                                    
                                        <div className='row mt-2'>
                                            <div className='col-12'>
                                                {
                                                    (() => {
                                                    if (value.comment_2 && value.description) {
                                                    return  <div className="card bg-light py-2 px-2 mt-2">
                                                                <div className="quote3 mb-3">{value.comment_2}</div> 
                                                                
                                                                {
                                                                    (() => {
                                                                            if (value.date_2) {
                                                                                return <div className="blockquote-footer"><i>{value.people_txt2} ({value.date_2})</i></div>
                                                                            }
                                                                            return <div className="blockquote-footer"><i>{value.people_txt2}</i></div>
                                                                    })()
                                                                }
                                                        
                                                            </div>
                                                    } 
                                                    return
                                                    })()
                                                }
                                            </div>
                                        </div>
                                            
                                    </div>               
                                    
                                        
                                    {/* 3 kolonne */}
                                    <div className="col-sm-5">

                                        <div className="row">
                                        
                                            <div className="col-sm-7">
                                                    <div>
                                                        
                                                        {
                                                            (() => {
                                                            if (value.recorded) {
                                                            return <div><b>Recorded </b><br />
                                                                <div className="Date p-1 rounded">{value.recorded}</div>
                                                                </div>
                                                            }
                                                            if (!value.recorded && value.rd_text) {
                                                            return <div><b>Recorded </b><br />
                                                                    <div className="Date p-1 rounded">{value.rd_text}</div>
                                                                    </div>
                                                            }
                                                            return
                                                            })()
                                                        }
                                                        
                                                        {
                                                            (() => {
                                                            if (value.studio_txt) {
                                                            return <div>
                                                                        <b>Location</b> <br />
                                                                        <div className="factText p-1 rounded">{value.studio_txt}</div>
                                                                        
                                                                    </div>
                                                            } 
                                                            return 
                                                            })()
                                                        }

                                                        {
                                                            (() => {
                                                            if (value.producer) {
                                                            return <div>
                                                                        <b>Producer</b> <br />
                                                                        <div className="factText p-1 rounded">{value.producer}</div>
                                                                        
                                                                    </div>
                                                            } 
                                                            return 
                                                            })()
                                                        }
                                                    
                                                        {
                                                            (() => {
                                                            if (value.engineer) {
                                                            return <div><b>Engineer</b> <br />
                                                            <div className="factText p-1 rounded">{value.engineer}</div>
                                                            
                                                            </div>
                                                        
                                                            } 
                                                            return
                                                            })()
                                                        }

                                                        {
                                                            (() => {
                                                            if (value.line_up && !value.engineer && !value.producer && !value.studio_txt && !value.recorded && !value.rd_text) {
                                                            return <div>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <b>Probable line-up</b> <br />
                                                                            <div className="factText px-2">
                                                                                <ReadMoreAndLess
                                                                                    charLimit={LineUpCharLimit}
                                                                                    readMoreText={" Read more ▼"}
                                                                                    readLessText={" Read less ▲"}
                                                                                    readMoreClassName="read-more-less--more"
                                                                                    readLessClassName="read-more-less--less"
                                                                                    >
                                                                                    {value.line_up}
                                                                                </ReadMoreAndLess>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        
                                                            } 
                                                            return
                                                            })()
                                                        }

                                                        {value.genre &&
                                                            <div>
                                                                <b>Genre</b> <br />
                                                                <div className="factText p-1 rounded">{value.genre}</div>
                                                            </div>
                                                        }

                                                        
                                                    </div>

                                            </div>

                                            <div className="col-sm-5">
                                                    
                                                    {
                                                            (() => {
                                                            if (value.single_released) {
                                                            return <div>
                                                                        <b>Single released</b> <br />
                                                                        <div className="Date p-1 rounded">{value.single_released}</div>
                                                                    </div>
                                                            } else if (value.sr_text) {
                                                            return <div>
                                                                        <b>Single released</b> <br />
                                                                        <div className="Date p-1 rounded">{value.sr_text}</div>
                                                                    </div>
                                                            }
                                                            return 
                                                            })()
                                                    }

                                                    {
                                                        (() => {
                                                        if (value.album) {
                                                        return <div><b>Album</b> <br />
                                                        <div className="factText p-1 rounded">{value.album}</div>
                                                            {value.album_rd &&
                                                            <div className="Date p-1 rounded"><i>{value.album_rd}</i></div>
                                                            }
                                                            {value.ar_text && !value.album_rd &&
                                                            <div className="Date p-1 rounded"><i>{value.ar_text}</i></div>
                                                            }
                                                        </div>
                                                    
                                                        } 
                                                        return 
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                        if (!value.album && value.album_txt) {
                                                        return <div><b>Album</b> <br />
                                                        <div className="factText p-1 rounded">{value.album_txt}</div>
                                                            {value.album_released &&
                                                            <div className="factText p-1 rounded"><i>{value.album_released}</i></div> 
                                                            }
                                                        </div>
                                                        } 
                                                        return 
                                                        })()
                                                    }

                                                    {value.length &&
                                                    <div><b>Length</b> <br />
                                                        <div className="factText p-1 rounded">{value.length}</div>
                                                    </div>
                                                    }

                                                    {
                                                        (() => {
                                                        if (value.label) {
                                                        return <div><b>Label</b> <br />
                                                        <div className="factText p-1 rounded">{value.label}</div>
                                                        </div>
                                                    
                                                        } 
                                                        return 
                                                        })()
                                                    }

                                                    {
                                                        (() => {
                                                        if (value.performed_onstage && !value.times_live) {
                                                            return <div><b>Performed onstage</b> <br />
                                                            <div className="factText p-1 rounded">{value.performed_onstage}</div>
                                                            
                                                            </div>
                                                        } 
                                                        if (!value.performed_onstage && value.times_live) {
                                                            return <div><b>Performed onstage</b> <br />
                                                            <div className="factText p-1 rounded"><i>-> <b>{value.times_live}</b> times</i></div>
                                                            <div className="factText p-1 rounded"><b>First time:</b> {value.first_live} <br /> 
                                                            <b>Last time:</b> {value.last_live}</div>
                                                            
                                                            </div>
                                                        } 

                                                        return
                                                        })()
                                                    }

                                                    {
                                                        (() => {
                                                        if (value.outtake && !value.live_version) {
                                                            return <div className='mt-2'>
                                                                    <button type="button" className="btn btn-info btn-xs">Outtake/Demo</button>
                                                                    </div>
                                                        }
                                                        if (value.live_version && value.outtake) {
                                                            return <div><button type="button" className="btn btn-info btn-xs mb-1">Live</button><br />
                                                            <button type="button" className="btn btn-info btn-xs">Outtake</button>
                                                            </div>
                                                        }
                                                        if (value.live_version && value.alt_version) {
                                                            return <div><button type="button" className="btn btn-info btn-xs">Live</button><br />
                                                            <button type="button" className="btn btn-info btn-xs">Alt. version</button>
                                                            </div>
                                                        }
                                                        if (value.live_version && !value.outtake) {
                                                            return <button type="button" className="btn btn-info btn-xs">Live</button>
                                                        }
                                                        if (value.alt_version) {
                                                            return <button type="button" className="btn btn-info btn-xs">Alt. version</button>
                                                        }
                                                        return 
                                                        })()
                                                    }
                                            
                                            </div>

                                        </div>

                                        {
                                            (() => {
                                            if (value.performed_onstage && value.times_live) {
                                                return <div className='mt-2'><b>Performed onstage</b> <br />
                                                <div className="factText p-1 rounded">{value.performed_onstage}</div>
                                                <div className="factText p-1 rounded"><i>-> <b>{value.times_live}</b> times</i></div>
                                                <div className="factText p-1 rounded"><b>First time:</b> {value.first_live} <br /> 
                                                <b>Last time:</b> {value.last_live}</div>
                                                </div>
                                                } 
                                            })()
                                        }

                                        {
                                            (() => {
                                            if (value.line_up && (value.engineer || value.producer || value.studio_txt 
                                                || value.recorded || value.rd_text)) {
                                            return  <div className="mt-2">
                                                        {(markdown_personnel || value.markdown_line_up) &&
                                                            <div>
                                                                <b>Probable line-up</b> <br />
                                                                <div className="factTextMarkdown p-1 rounded">
                                                                    <ReactMarkdown>
                                                                        {value.line_up}                    
                                                                    </ReactMarkdown> 
                                                                </div>
                                                            </div>
                                                        }

                                                        {!markdown_personnel && !value.markdown_line_up &&
                                                            <div className="row pt-3">
                                                                <div className="col-12">
                                                                    <b>Probable line-up</b> <br />
                                                                    <div className="factText px-2 rounded">
                                                                        <ReadMoreAndLess
                                                                            charLimit={LineUpCharLimit}
                                                                            readMoreText={" Read more ▼"}
                                                                            readLessText={" Read less ▲"}
                                                                            readMoreClassName="read-more-less--more"
                                                                            readLessClassName="read-more-less--less"
                                                                            >
                                                                            {value.line_up}
                                                                        </ReadMoreAndLess>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        
                                                    </div>
                                            } 
                                            return
                                            })()
                                        }
                
                                        <div className="row pt-3">   
                                            <div className="col-12">

                                                {value.main_comment &&
                                                    <div className="card bg-light px-2 pt-2">
                                                        <div className="quote3 mb-3">{value.main_comment}</div> 
                                                        <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                                                    </div>
                                                }

                                                {
                                                    (() => {
                                                        if (value.comment && value.description) {
                                                            return <div className="card bg-light px-2 pt-2">
                                                                        <div className="quote3 mb-3">{value.comment}</div> 
                                                                        
                                                                        {
                                                                            (() => {
                                                                                    if (value.date_txt) {
                                                                                        return <div className="blockquote-footer"><i>{value.people_txt} ({value.date_txt})</i></div>
                                                                                    }
                                                                                    return <div className="blockquote-footer"><i>{value.people_txt}</i></div>

                                                                            })()

                                                                        }
                
                                                                    </div>
                                                            }
                                                
                                                    })()
                                                }
                                                
                                                {
                                                    (() => {
                                                    if (value.comment_2 && !value.description) {
                                                    return  <div className="card bg-light px-2 pt-2 mt-2">
                                                                <div className="quote3 mb-3">{value.comment_2}</div> 
                                                                
                                                                {
                                                                    (() => {
                                                                            if (value.date_2) {
                                                                                return <div className="blockquote-footer"><i>{value.people_txt2} ({value.date_2})</i></div>
                                                                            }
                                                                            return <div className="blockquote-footer"><i>{value.people_txt2}</i></div>
                                                                    })()
                                                                }
                                                        
                                                            </div>
                                                    } 
                                                    return
                                                    })()
                                                }
                                            
                                                {
                                                    (() => {
                                                    if (value.comment_3 && comment3) {
                                                    return <div className='mt-3'>
                                                                <div className="card bg-light px-2 pt-2s">
                                                                    <div className="quote3 mb-3">{value.comment_3}</div> 
                                                                    {
                                                                        (() => {
                                                                                if (value.date_3) {
                                                                                    return <div className="blockquote-footer"><i>{value.people_txt3} ({value.date_3})</i></div>
                                                                                }
                                                                                return <div className="blockquote-footer"><i>{value.people_txt3}</i></div>
                                                                        })()
                                                                    }
                                                                </div>
                                                            </div>
                                                    } 
                                                    return 
                                                    })()
                                                }


                                                
                                            </div>
                                        </div> 

                                        {
                                            (() => {
                                            if (show_other_versions) {
                                            return <div>
                                                        {
                                                            (() => {
                                                            if (value.video1) {
                                                                return <div>
                                                                            <div className="row">
                                                                                <div className='col-12'>
                                                                                    <div className='videoTitle pl-2 mx-2' align='center'><strong>Other versions</strong></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-2">
                                                                                <div className='col-sm-6 mb-2'>
                                                                                    <div className="card bg-light py-2">
                                                                                        <div className="mx-1">  
                                                                                            <ReactPlayer
                                                                                                url={ value.video1 }
                                                                                                        light='true'
                                                                                                        //light
                                                                                                        controls
                                                                                                        width="280"
                                                                                                        height="200"
                                                                                            />
                                                                                        </div>
                                                                                        <div className='DescriptionBig2 pl-1'>
                                                                                            <strong>{value.desc1}</strong>
                                                                                        </div>
                                                                                    </div>
                                                                                    </div>
                                                                                <div className='col-sm-6 mb-2'>
                                                                                    {
                                                                                        (() => {
                                                                                        if (value.video2) {
                                                                                            return <div className="card bg-light py-2">
                                                                                                        <div className="mx-1">  
                                                                                                            <ReactPlayer
                                                                                                                url={ value.video2 }
                                                                                                                        light='true'
                                                                                                                        //light
                                                                                                                        controls
                                                                                                                        width="280"
                                                                                                                        height="200"
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className='DescriptionBig2 pl-1'>
                                                                                                            <strong>{value.desc2}</strong>
                                                                                                        </div>
                                                                                                    </div>
                                                                                        }
                                                                                        return 
                                                                                        })()
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                            }
                                                            return 
                                                            })()
                                                        }
                                                        {
                                                            (() => {
                                                            if (value.video3) {
                                                                return <div>
                                                                            <div className="row mt-1">
                                                                                <div className='col-sm-6 mb-2'>
                                                                                    <div className="card bg-light py-2">
                                                                                        <div className="mx-1">  
                                                                                            <ReactPlayer
                                                                                                url={ value.video3 }
                                                                                                        light='true'
                                                                                                        //light
                                                                                                        controls
                                                                                                        width="280"
                                                                                                        height="200"
                                                                                            />
                                                                                        </div>
                                                                                        <div className='DescriptionBig2 pl-1'>
                                                                                            <strong>{value.desc3}</strong>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-sm-6 mb-2'>
                                                                                    {
                                                                                        (() => {
                                                                                        if (value.video4) {
                                                                                            return <div className="card bg-light py-2">
                                                                                                        <div className="mx-1">  
                                                                                                            <ReactPlayer
                                                                                                                url={ value.video4 }
                                                                                                                        light='true'
                                                                                                                        //light
                                                                                                                        controls
                                                                                                                        width="280"
                                                                                                                        height="200"
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className='DescriptionBig2 pl-1'>
                                                                                                            <strong>{value.desc4}</strong>
                                                                                                        </div>
                                                                                                    </div>
                                                                                        }
                                                                                        return 
                                                                                        })()
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                            }
                                                            return 
                                                            })()
                                                        }
                                                        
                                                    </div>
                                            } 
                                            return 
                                            })()
                                        }

                                        {(value.blues_hall_of_fame || value.grammy_hall_of_fame || value.library_of_congress) &&
                                            <div className="p-3 mt-3 border rounded bg-success text-white Description">
                                                {value.blues_hall_of_fame &&
                                                <div className="mt-1 ms-3"><b>Blues Hall of Fame: </b> 
                                                {value.blues_hall_of_fame}
                                                </div>
                                                }
                                                {value.grammy_hall_of_fame &&
                                                    <div className="mt-1 ms-3"><b>Grammy Hall of Fame: </b> 
                                                    {value.grammy_hall_of_fame}
                                                    </div>
                                                }
                                                {value.library_of_congress &&
                                                    <div className="mt-1 ms-3"><b>National Recording Registry: </b> 
                                                    {value.library_of_congress}
                                                    </div>
                                                }
                                            </div>
                                        }
                                    
                                    </div>
                                        
                                    
                                </div>
                                
                        })()
                    }       

            </React.Fragment>  
        )

     }
    )

    //SONGS CAME CLOSE
    const songsCameCloseItems = cameCloseSongs.map ( value => {

        let DescCharLimit2

        if (value.desc_selected_songs) {
            DescCharLimit2 = value.desc_selected_songs;
        }
        else {
            DescCharLimit2 = 350
        }
        return (
            <React.Fragment key={value.key_id}>
                <div className="row pt-3 pb-3 border-bottom border-top border-info">

                    {/* 1 kolonne */}
                    <div className="col-sm-2 text-center">
                        {
                            (() => {
                            if (value.list_id === 128) {
                                return <div className="Song2 mb-2 rounded"><b>{value.rank} - {value.list_choice_comment}</b></div>
                                }
                            if (value.rank) {
                                return  <div className='Song mb-2 rounded'><b>#{value.rank} <br /> {value.title}</b></div> 
                            }
                                return <div className='Song2 mb-2 rounded'><b>{value.title}</b></div> 
                            })()
                        }
                    
                        <div align="center">
                            {
                                (() => {
                                if (value.picture && !value.pic_link) {
                                return  <img src={value.picture} alt=""
                                className="img-fluid"></img>
                                }
                                if (!value.picture && value.pic_link) {
                                    return  <img src={value.pic_link} alt=""
                                    className="img-fluid"></img>
                                    }
                                return <img src={value.album_picture} className="img-fluid" alt=""></img>
                                })()
                            }
                        </div>
                    </div>

                    {/* 2 kolonne */}
                    <div className="col-sm-2">
                        <div align="center">
                            <div className="Song2 mb-3 rounded"><b>{value.artist}</b></div>
                        </div> 
                            
                            {
                                (() => {
                                if (value.songwriter) {
                                return <div><b>Songwriter</b> <br />
                                <div className="factText px-2 mb-1">{value.songwriter}</div>
                                </div>
                            
                                } 
                                return 
                                })()
                            }
                            {
                                (() => {
                                if (value.producer) {
                                return <div><b>Producer</b> <br />
                                <div className="factText px-2 mb-1">{value.producer}</div>
                                </div>
                            
                                } 
                                return 
                                })()
                            }
                            {
                                (() => {
                                if (value.label) {
                                return <div><b>Label</b> <br />
                                <div className="factText px-2 mb-1">{value.label}</div>
                                </div>
                            
                                } 
                                return 
                                })()
                            }
                    </div>

                    {/* 3 kolonne */}
                    <div className="col-sm-3">
                        <div className="row">
                            <div className="col border-right Description">

                                {
                                (() => {
                                if (value.description) {
                                return  <div>
                                            <ReadMoreAndLess
                                                    charLimit={DescCharLimit2}
                                                    readMoreText={" Read more ▼"}
                                                    readLessText={" Read less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.description}
                                            </ReadMoreAndLess>
                                            <div className="Description"> 
                                                <br /> <em> -> <a href={value.wiki}>more info..</a></em>
                                                </div>
                                    </div>
                                }
                                if (!value.description && value.comment) {
                                    return <div className="card bg-light py-2 px-2">
                                                <div className="quote3 mb-3">{value.comment}</div>
                                                    {
                                                        (() => {
                                                                if (value.date_txt) {
                                                                    return <div className="blockquote-footer"><i>{value.people_txt} ({value.date_txt})</i></div>
                                                                }
                                                                return <div className="blockquote-footer"><i>{value.people_txt}</i></div>

                                                        })()

                                                    }
                                        </div>
                                    }
                                    return <div>
                                                <ReadMoreAndLess
                                                        charLimit={300}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                    >
                                                    {value.lyrics}
                                                </ReadMoreAndLess>
                                        </div>
                                    })()
                                }
                                
                            </div>

                        </div>
                    </div>

                    {/* 4 kolonne */}
                    <div className="col-sm-2">
                            {
                                (() => {
                                if (value.recorded) {
                                return <div><b>Recorded </b><br />
                                            <div className="Date px-2">{value.recorded}</div>
                                    </div>
                                }
                                if (value.rd_text) {
                                return <div><b>Recorded </b><br />
                                    <div className="Date px-2">{value.rd_text}</div>
                                    </div>
                                }
                                return
                                })()
                            }
                            {
                                (() => {
                                if (value.studio_txt) {
                                return <div>
                                            <b>Location</b> <br />
                                            <div className="factText px-2">{value.studio_txt}</div>
                                            
                                        </div>
                                } 
                                return 
                                })()
                            }
                            {
                                    (() => {
                                    if (value.single_released) {
                                    return <div>
                                                <b>Single released</b> <br />
                                                <div className="Date px-2">{value.single_released}</div>
                                            </div>
                                    } else if (value.sr_text) {
                                    return <div>
                                                <b>Single released</b> <br />
                                                <div className="Date px-2">{value.sr_text}</div>
                                            </div>
                                    }
                                    return 
                                    })()
                            }
                            {
                                (() => {
                                if (value.album) {
                                return <div><b>Album</b> <br />
                                <div className="factText px-2">{value.album}</div>
                                <div className="Date px-2"><i>{value.album_rd||value.ar_text}</i></div>
                                </div>
                            
                                } 
                                return 
                                })()
                            }
                            {
                                (() => {
                                if (!value.album && value.album_txt) {
                                return <div><b>Album</b> <br />
                                <div className="factText">{value.album_txt}</div>
                                </div>
                                } 
                                return 
                                })()
                            }
                            
                    </div>

                    {/* 5 kolonne */}
                    <div className="col-sm-3 pb-1 border-right Description">
                        {
                            (() => {
                            if (value.youtube) {
                                return <div>
                                        <div className='mt-2' align='center'><h5>Click to play</h5></div>
                                            <div className="mt-2">
                                                <ReactPlayer
                                                    url={ value.youtube }
                                                            light='true'
                                                            //light
                                                            controls
                                                            width="350"
                                                            height="300"
                                                />
                                            </div>
                                        </div>
                            }
                            return
                            })()
                        }


                    </div>

                    
                    
                
                </div>

            </React.Fragment>
        )

        }
    )

    //REFERENCES
    const referenceItems = sortedReferences.map(value => {
        return (
            <React.Fragment key={value.id}>
                {
                        (() => {
                        if (value.book_title) {
                        return  <div className="row px-1 my-1">
                                    <div className="col">
                                        <div>{value.author_txt} ({value.published_year}).
                                        <a href={value.more_info}> {value.book_title}</a>. {value.publisher}. (book) </div>
                                    </div>
                                </div>
                        } if (!value.date_txt && value.person) {
                        return <div className="row px-1 my-1">
                                <div className="col">
                                    <a href={value.url}>
                                        {value.title}
                                    </a> ({value.person})
                                </div>
                            </div>
                        } if (!value.date_txt && !value.person) {
                            return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        }
                        if (!value.person) {
                            return <div className="row px-1 my-1">
                                        <div className="col">
                                            <a href={value.url}>
                                                {value.title}
                                            </a> ({value.date_txt})
                                        </div>
                                    </div>
                        }
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        {value.person} ({value.date_txt}). <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        })()
                    }
            </React.Fragment>
        )
    }
    )
     



    return (
        <main className="my-1 py-1">
            {list.length > 0 && (
                <div className="container">
                
                    <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={picture_link}
                    url={'https://www.musicthisday.com/'+page}
                    canonical={'https://www.musicthisday.com/'+page}
                    type='article' />

                    <div className="row">
                        <div className="col">
                            {listHeading}
                        </div>
                    </div>

                    {!AgGrid && 
                    <div className="row">
                        <div className="col">

                            {
                                (() => {
                                if (songs.length > 0) {
                                return <div> { songItems } </div>
                                }
                                return <div>
                                        <h3>No songs found</h3>
                                    </div>
                                })()
                            }
                            

                             {
                                (() => {
                                if ((songs_must_hear_year) && (songsCameCloseItems.length > 0)) {
                                return <div>
                                            <div className="row mt-5 mb-2">
                                                <div className="col">
                                                    <div className='Header px-3 rounded'>
                                                        A few more great songs released in {year_released}
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="row">
                                                <div className="col">
                                                        {songsCameCloseItems}
                                                </div>
                                            </div>  
                                        </div>
                                } 
                                if (new_songs_pending) {
                                return <div>
                                            <div className="row mt-5 mb-2">
                                                <div className="col">
                                                    <div className='Header px-3 rounded'>
                                                        New songs pending rank...
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="row">
                                                <div className="col">
                                                        {songsCameCloseItems}
                                                </div>
                                            </div>  
                                        </div>
                                }
                                return 
                                })()
                            } 

                        </div>
                    </div>
                    }

                    {AgGrid && 
                    <div className="row pr-2 pl-2 pt-3">
                        <div id="myGrid" style={{ height: '2000px', width: '1200px' }} className="ag-theme-balham">
                            <AgGridReact
                                rowSelection="multiple"
                                pagination={true}
                                rowData={songs}
                                columnDefs={columnDefs}>
                            </AgGridReact>
                        </div>
                    </div>
                    }

                    {
                        (() => {
                        if (references.length > 0) {
                        return  <div>
                                        <div className="row mt-4">
                                            <div className="col">
                                                    <h4>References & similar lists</h4>
                                            </div>
                                        </div>
                                        <div className="row px-1">
                                            <div className="col">
                                                <b>
                                                <a href='https://en.wikipedia.org'>
                                                    Wikipedia
                                                </a>
                                                </b>
                                            </div>
                                        </div>
                                    
                                        <div className="row my-2">
                                            <div className="col">
                                                    { referenceItems }
                                            </div>
                                        </div>
                                </div>
                        }
                        return 
                        })()
                    }
                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>



                </div>
            )}
        </main>
    )



}
export default SongLists